import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navbar } from "../components/navbar";
import Users from "./Users";
import User from "./User";
import Statistics from "./statistics";
import CustomerSupport from "./ticket";
import TicketDetails from "./ticket/ticketDetails";
import Version from "./version";

const routes = [
  {path: '/users', element: Users},
  {path: '/users/:id', element: User},
  {path: '/statistics', element: Statistics},
  {path: '/customer-support', element: CustomerSupport},
  {path: '/ticket/:id', element: TicketDetails},
  {path: '/version', element: Version},
]

export function UserAdminView() {

  return (
    <div className="grid grid-cols-12 z-10 flex-grow h-full overflow-y-hidden">
      <Router>
        <Navbar />
        <div className="col-span-11 flex flex-col bg-Blue-ligth p-6 h-full overflow-y-auto">
          <Routes>
            {routes.map((r) => <Route path={r.path} element={<r.element/>}/>)}
          </Routes>
        </div>
      </Router>
    </div>
  );
}
