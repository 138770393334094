import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/users.context";
import { Role } from "../../@types/users";

interface TabProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
}

function Tab({ title, isActive, onClick, disabled }: TabProps) {
  return (
    <button
      disabled={disabled}
      className={`px-4 py-2 rounded-md ${
        isActive ? "bg-Yellow-default text-black" : "bg-gray-200 text-black"
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export function Navbar() {
  const location = useLocation();
  const mainPath = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const { loggedUser } = useAuth();
  const isAdmin = loggedUser?.role === Role.ADMIN;

  return (
    <div className="col-span-1 flex flex-col space-y-4 p-4 h-full bg-Grey-background-2">
      {isAdmin && (
        <>
          <Tab
            title="Users"
            isActive={mainPath == "users"}
            onClick={() => navigate("users")}
          />
          <Tab
            title="Statistics"
            isActive={mainPath == "statistics"}
            onClick={() => navigate("statistics")}
          />
          <Tab
            title="Customer Support"
            isActive={mainPath == "customer-support"}
            onClick={() => navigate("customer-support")}
          />
          <Tab
            title="MAINTENAID Version"
            isActive={mainPath == "version"}
            onClick={() => navigate("version")}
          />
        </>
      )}
    </div>
  );
}
